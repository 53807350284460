import { defineStore } from 'pinia'
import { computed } from 'vue'
import moment from 'moment'
import useAPI from '@/composables/useApi'
import { useListWithCheck } from '@/composables'
import { StatusData } from '@/datas/community'
import * as STATES from '@/datas/users/states'
import { datesCompare } from '@/utils/dates'

moment.locale('fr')

export const useContactsStore = defineStore('useContactsStore', () => {
  const listWithCheck = useListWithCheck()

  const fetch = async () => {
    const API = useAPI()
    const response = await API.fetch('/contacts', { method: 'get' })
    const data = mapData(response.data)
    listWithCheck.setCheckList(data)
    return response
  }

  const mapData = (data) => {
    if (typeof data === 'object') {
      return data.map((item) => {
        item.name = item.firstName + ' ' + item.lastName
        item.status = item.state
        item.logo = null

        if (item.state === 1) {
          item.status = StatusData.STATUS_COMPLIANT

          if (item.certified) {
            item.status = StatusData.STATUS_CERTIFIED
          }
        } else if (item.state === 2) {
          item.status = StatusData.STATUS_LOCKED
        } else if (item.state === 3) {
          item.status = StatusData.STATUS_DELETED
        }
        return item
      })
    }
  }

  const setLogo = (url, id) => {
    listWithCheck.list.value.forEach((item) => {
      if (item.id === id) {
        item.logo = url
      }
    })
  }

  const waitingContacts = computed(() => {
    return listWithCheck.list.value
      .filter((item) => item.state === STATES.USER_STATE_WAITING)
      .map((item) => {
        item.daysSinceCreation = moment().diff(moment(item.createdAt), 'days')
        return item
      })
  })

  const enabledContacts = computed(() => {
    return listWithCheck.list.value.filter((item) => item.state === STATES.USER_STATE_ENABLED)
  })

  const disabledContacts = computed(() => {
    return listWithCheck.list.value.filter((item) => item.state === STATES.USER_STATE_DISABLED)
  })

  const deletedContacts = computed(() => {
    return listWithCheck.list.value.filter((item) => item.state === STATES.USER_STATE_DELETED)
  })

  const certifiedContacts = computed(() => {
    return listWithCheck.list.value.filter((item) => item.certified)
  })

  const lastUpdated = computed(() => {
    return listWithCheck.list.value.length
      ? listWithCheck.list.value.reduce((acc, item, index) => {
        if (index === 0) {
          acc = item
        } else {
          if (datesCompare(item.updatedAt, acc.updatedAt)) {
            acc = item
          }
        }
        return acc
      })
      : false
  })

  const lastUpdatedFormatedDate = computed(() => {
    return moment(lastUpdated.value.updatedAt).format('LL')
  })

  const lastUpdatedFormatedHour = computed(() => {
    return moment(lastUpdated.value.updatedAt).format('LT')
  })

  return {
    ...listWithCheck,
    setLogo,
    waitingContacts,
    enabledContacts,
    disabledContacts,
    deletedContacts,
    certifiedContacts,
    lastUpdated,
    lastUpdatedFormatedDate,
    lastUpdatedFormatedHour,
    fetch
  }
})
